import React from 'react';
import Link from 'next/link';
import {
  Typography,
  makeStyles,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  CardActions,
  IconButton,
} from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import EditIcon from '@material-ui/icons/Edit';

import { htmlToText } from 'html-to-text';

export default function PostFeed({ posts, my }) {
  return posts ? posts.map((post) => <PostItem post={post} key={post.slug} my={my} />) : null;
}

const useStyles = makeStyles({
  // root: {
  //   maxWidth: 345,
  // },
  media: {
    minHeight: '177px',
  },
  cardContent: {
    padding: '12px',
  },
  cardAction: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 12px',
  },
  userInfo: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  userName: {
    marginLeft: '8px',
  },
  heart: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
  },
  description: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
  },
});

function PostItem({ post, my = false }) {
  const classes = useStyles();

  return (
    <>
      <Card variant="outlined" className={classes.card}>
        {post.postLink ? (
          post.postLink.includes('youtube.com') ? (
            <Link href={determinePostLink(post)} passHref>
              <PostItemTop post={post} target="_self" />
            </Link>
          ) : (
            <Link href={determinePostLink(post)} passHref>
              <PostItemTop post={post} target="_blank" />
            </Link>
          )
        ) : (
          <Link href={determinePostLink(post)} passHref>
            <PostItemTop post={post} target="_self" />
          </Link>
        )}

        <Link href={`/${post.username}`} passHref>
          <PostItemBottom post={post} target="_self" />
        </Link>
        <CardActions disableSpacing>
          {my && (
            <>
              <Link href={`/my/${post.slug}`}>
                <IconButton aria-label="edit">
                  <EditIcon />
                </IconButton>
              </Link>

              {post.published ? (
                <Typography color={'primary'}>公開中</Typography>
              ) : (
                <Typography color={'error'}>下書き</Typography>
              )}
            </>
          )}
        </CardActions>
      </Card>
    </>
  );
}

const PostItemTop = React.forwardRef(({ post, target, onClick, href }, ref) => {
  const classes = useStyles();

  return (
    <a target={target} href={href} onClick={onClick} ref={ref}>
      <CardActionArea>
        {post.landingImage ? (
          <CardMedia className={classes.media} image={post.landingImage} title={post.title} />
        ) : (
          <CardMedia
            className={classes.media}
            image="default-landing-image.svg"
            title={post.title}
          />
        )}
        <CardContent className={classes.cardContent}>
          <Typography gutterBottom variant="h7" component="h2" className={classes.title}>
            {post.title}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            className={classes.description}
          >
            {htmlToText(post.content, {
              wordwrap: 130,
              tags: {
                img: {
                  format: 'skip',
                },
              },
            })}
          </Typography>
        </CardContent>
      </CardActionArea>
    </a>
  );
});

const PostItemBottom = React.forwardRef(({ post, target, onClick, href }, ref) => {
  const classes = useStyles();

  return (
    <a target={target} href={href} onClick={onClick} ref={ref}>
      <CardActions disableSpacing className={classes.cardAction}>
        <div className={classes.userInfo}>
          <Avatar src={post.userPhotoURL} />
          <Typography variant="subtitle2" className={classes.userName}>
            by <strong>{post.username}</strong>
          </Typography>
        </div>
        <div className={classes.heart}>
          <IconButton disabled>
            <FavoriteBorderIcon style={{ color: '#da6e93' }} />
          </IconButton>
          <Typography>{post.heartCount || 0}</Typography>
        </div>
      </CardActions>
    </a>
  );
});

const determinePostLink = (post) => {
  // Link Post is YouTube link
  if (post.postLink && post.postLink.includes('youtube.com')) {
    return `/${post.username}/${post.slug}`;
  }

  // Original Post in Shukream
  if (!post.postLink) {
    return `/${post.username}/${post.slug}`;
  }

  return post.postLink;
};
